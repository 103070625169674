import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Main from '../layouts/Main';
import Container from '../components/Container';
import Hero from "../pages/mob/Hero";
import {Footer} from "../layouts/Main/components";
import MobInfo from "./mob/MobInfo";
import {OntoNavbar} from "../components/OntoNavbar";

const Mob = (): JSX.Element => {
    const theme = useTheme();

    return (
        <Main>
            <OntoNavbar></OntoNavbar>
            <Box
                bgcolor={'alternate.main'}
                sx={{
                    position: 'relative',
                    '&::after': {
                        position: 'absolute',
                        content: '""',
                        width: '30%',
                        zIndex: 1,
                        top: 0,
                        left: '5%',
                        height: '100%',
                        backgroundSize: '18px 18px',
                        backgroundImage: `radial-gradient(${alpha(
                            theme.palette.primary.dark,
                            0.4,
                        )} 20%, transparent 20%)`,
                        opacity: 0.2,
                    },
                }}
            >
                <Box position={'relative'} zIndex={3}>
                    <Hero />
                </Box>
            </Box>
            <Container>
                <MobInfo />
            </Container>
            <Container>
                <Footer />
            </Container>
        </Main>
    );
};

export default Mob;